.Header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Header span {
  font-weight: bolder;
  font-size: 40px;
}
.ImagemPrancha {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
