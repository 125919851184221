.listItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
}
.listItem span {
  font-size: 10px;
  font-weight: bold;
  margin-left: 15%;
}
.listItem p {
  font-size: 10px;
  font-weight: bold;
  margin-left: 15%;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 5%;
  color: #7c7c7d;
}
.listHeader span {
  margin-right: 15%;
}
.listHeader label {
  margin-left: -8%;
}
.listIcone {
  margin-left: -18%;
}
.filters {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1%;
}
.filters input {
  background: #f6f6f6;
  border-radius: 6px;
  border-color: #7c7c7d;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #7c7c7d;
  margin-left: 1%;
}
