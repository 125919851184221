@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap);
.MainClass {
    width: 100vw;
    height: 100vh;
    background-color: #f1f6f9;
    display: flex;
}

.loginInput {
    flex-wrap: wrap;
    max-width: 400px;
    align-items: center;
    margin-left: 40%;
    margin-right: 40%;
    align-items: center;
    font-family: 'Popins', sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    box-shadow: black;
}
.loginInput button {
    align-self: center;
    font-family: 'Roboto', sans-serif;
    background-color: #222831;
    box-shadow: #222831;
    cursor: pointer;
}
.loginInput h3 {
    align-self: center;
    text-align: center;
    font-family: 'Popins', sans-serif;
    font-weight: 500;
}

@media (max-width: 800px) {
    .loginInput {
        width: 1000px;
        height:1000px;
        max-width: 380px;
        margin-left: 2%;
        margin-right: 2%;
        align-items: center;
        font-family: 'Popins', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        box-shadow: black;
    }
}

.MainDashboardMain {
  width: 100%;
  height: 100%;
  background-color: #edf4f7;
  box-sizing: border-box;
}
.Formulario-pedido {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
}
.Formulario-pedido > container {
  flex: 1 1 200px;
}
@media (max-width: 100px) {
  .main {
    margin-top: 10%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .DataCards {
    flex-wrap: wrap;
    margin-left: 12%;
  }
  .sidebar {
    height: 50%;
  }
}

.navBar {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: medium;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
}

.Header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Header span {
  font-weight: bolder;
  font-size: 40px;
}
.ImagemPrancha {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.listItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
}
.listItem span {
  max-width: 250ch;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.listItem p {
  margin-left: 10px;
}
.listItem p:last-child {
  margin-left: 45px;
}
.Items {
  width: 100%;
  background-color: #7c7c7d;
  color: white;
}

.listItems {
  width: 110%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  white-space: nowrap;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
}
.listItems p {
  margin-left: -15px;
}
.listItems span {
  margin-left: -25px;
}

.listItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
}
.listItem span {
  font-size: 10px;
  font-weight: bold;
  margin-left: 15%;
}
.listItem p {
  font-size: 10px;
  font-weight: bold;
  margin-left: 15%;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 5%;
  color: #7c7c7d;
}
.listHeader span {
  margin-right: 15%;
}
.listHeader label {
  margin-left: -8%;
}
.listIcone {
  margin-left: -18%;
}
.filters {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 1%;
}
.filters input {
  background: #f6f6f6;
  border-radius: 6px;
  border-color: #7c7c7d;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #7c7c7d;
  margin-left: 1%;
}

.App {
    width: 100vw;
    height: 100vh;
    background-color: #f1f6f9;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
}


