.listItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  white-space: nowrap;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
}
.listItem span {
  max-width: 250ch;
  min-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.listItem p {
  margin-left: 10px;
}
.listItem p:last-child {
  margin-left: 45px;
}
.Items {
  width: 100%;
  background-color: #7c7c7d;
  color: white;
}

.listItems {
  width: 110%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  white-space: nowrap;
  font-family: 'Work Sans', sans-serif;
  font-weight: 700;
}
.listItems p {
  margin-left: -15px;
}
.listItems span {
  margin-left: -25px;
}
